import React, { useEffect, useState } from 'react';

import ContactUsButton from './home/ContactUsButton';
import LazyLoadImageFromUrl from './common/LazyLoadImageFromUrl';
import devotedLogo from './../images/header-logo.png';
import fetchUrlFromCDN from '../services/CDNService';

export default function Header() {
  const [imageUrl, setImageUrl] = useState(devotedLogo);
  const [isHeaderVisible, setIsHeaderVisible] = React.useState(true);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        setIsHeaderVisible(entry.isIntersecting);
      });
    });
    let target = document.getElementById('page-header');
    observer.observe(target);
  }, []);

  useEffect(() => {
    fetchUrlFromCDN('devoted')
      .then((resp) => {
        'logo' in resp && setImageUrl(resp.logo);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <header
      id='page-header'
      className='flex justify-between w-full px-10 py-6 items-center h-10 md:h-20 page-header'
    >
      <div className='align-center md:ml-[0px] ml-[-30px]'>
        <a href='/' className='h-full'>
          <LazyLoadImageFromUrl
            imageUrl={imageUrl}
            fallbackSrc={devotedLogo}
            alt='devoted logo'
            className='h-8 md:h-10 lg:h-12'
          />
        </a>
      </div>
      <ContactUsButton isVisible={isHeaderVisible} />
    </header>
  );
}
