import { Rating } from '@mui/material';
import React from 'react';
import quotes from '../../images/quotes.jpg';

export default function CarouselItem({ rating, name, age, image, content }) {
  return (
    <div className='flex flex-row justify-evenly md:px-10 lg:px-10  md:gap-4 w-full pt-20 pb-4 md:pb-10 px-2'>
      <div className='absolute z-[-10] top-10 left-0 lg:left-4 xl:left-0 w-32'>
        <img src={quotes} alt='quote icon' />
      </div>
      <div className='flex flex-col sm:basis-8/12 lg:basis-9/12 2xl:basis-10/12 mx-5 md:mx-0'>
        <div className='xl:text-xl'>{content}</div>
        <div className='flex justify-between mt-8 mb-10 '>
          <div className='flex flex-col'>
            <span className='font-bold italic'>
              {name}
              {age && ', ' + age.toString()}
            </span>
            <Rating
              size='small'
              name='read-only'
              value={rating}
              precision={0.1}
              readOnly
            />
          </div>
          <div className=' h-20 w-20 bg-center  overflow-hidden shadow-md rounded-full mr-6 sm:hidden'>
            <img className='object-cover ' src={image} alt='Person'></img>
          </div>
        </div>
      </div>
      <div></div>
      <div className=' h-24 w-24 md:h-28 md:w-28 bg-center overflow-hidden shadow-md rounded-full hidden sm:block'>
        <img
          className='object-cover h-full w-full'
          src={image}
          alt='Person'
        ></img>
      </div>
    </div>
  );
}
