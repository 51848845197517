const plans = {
  HMO: 'HMO',
  PPO: 'PPO',
  PFFS: 'PFFS',
};

const coverages = {
  'Part c': 'Medicare Advantage',
  Ma: 'Medicare Advantage',
  'Med adv': 'Medicare Advantage',
  'Medicare advantage': 'Medicare Advantage',
};

const GENERIC_TERM = 'generic';

const UtmTermConstants = {
  plans,
  coverages,
  GENERIC_TERM,
};

export default UtmTermConstants;
