import HeroSectionContent from './HeroSectionContent';
import React from 'react';
import heroBg from '../../images/hero.jpg';

const HeroSection = ({ onSubmit, utmTerm, isMedicareAdvantage, isPDP }) => {
  return (
    <>
      <div className="bg-[url('../images/hero.jpg')] bg-cover bg-no-repeat bg-hero_bg_pos lg:bg-none lg:bg-custom_grey width-full flex justify-between xl:items-center min-h-[90vh] max-h-[90vh]">
        <div className='flex-1 flex flex-col justify-end xl:justify-center px-5 md:px-20 bg-gradient-to-b from-transparent via-[#fffc] to-[#fff] lg:bg-none'>
          <HeroSectionContent onSubmit={onSubmit} utmTerm={utmTerm} />
        </div>
        <div className='hidden lg:block flex-1 self-stretch'>
          <img
            src={heroBg}
            aria-label='hero image'
            style={{
              width: '100%',
              height: '130%',
              objectFit: 'cover',
              objectPosition: '100% 100%',
            }}
          />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
