import 'tw-elements';

import * as React from 'react';

import { ActionContext, fireEvent } from '../../cra';

import Cookies from 'universal-cookie';
import FormUtils from '../../utils/FormUtils';
import HomePage from '../home';
import PreLeadService from '../../services/PreLeadService';
import PropTypes from 'prop-types';
import SEO from '../home/SEO';
import { getSearchParams } from 'gatsby-query-params';
import { isEmpty } from 'lodash';

const cookies = new Cookies();
const maxAge = 365 * 24 * 60 * 60;
const brandName = 'devoted';
let qpUseEffectAlreadyRun = false;

const NavigationComponent = ({ isMedicareAdvantage, isPDP }) => {
  const qp = getSearchParams();
  const [ap, setAP] = React.useState('');
  const [jlID, setJLID] = React.useState('');

  const [cookieValues, setCookieValues] = React.useState({
    hotjarSessionCookie: '',
    leadClientIDGoogleCookie: '',
    originalLeadAdSourceCookie: '',
    originalLeadMediumCookie: '',
    originalLeadAdCampaignCookie: '',
    originalLeadAdGroupCookie: '',
    originalLeadDateCookie: '',
    originalLeadURLCookie: '',
    originalGoogleClientIDCookie: '',
    originalLeadNetwork: '',
    originalLeadMatchType: '',
    originalLeadKeyword: '',
    originalLeadQueryString: '',
    originalLeadContent: '',
    originalLeadExtension: '',
    originalLeadDevice: '',
    originalLeadLocation: '',
    originalLeadPlacement: '',
    originalLeadAdPosition: '',
    originalLeadDeviceBrand: '',
    originalLeadDeviceName: '',
    originalLeadOsVersion: '',
    originalLeadScreenHeight: '',
    originalLeadScreenWidth: '',
    originalLeadLandingPage: '',
    originalLeadReferringUrl: '',
    originalLeadIPAddress: '',
    originalLeadBrowser: '',
    originalLeadOS: '',
    originalLeadCampaignID: '',
    originalLeadAdGroupID: '',
    originalLeadKeywordID: '',
    originalLeadExtensionID: '',
    leadBrand: '',
  });
  const [state, setState] = React.useState({
    selectedComponent: undefined,
    zipCode: '',
    county: '',
    stateAbbrv: '',
    day: '',
    month: '',
    year: '',
    gender: '',
    spouse: '',
    spouse_month: '',
    spouse_year: '',
    spouse_day: '',
    spouse_gender: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    sms_opt_in: 'false',
    term_id: '',
  });

  var leadData = {
    postalCode: state.zipCode,
    firstName: state.first_name,
    lastName: state.last_name,
    birthdate: state.year + '-' + state.month + '-' + state.day,
    gender: state.gender,
    email: state.email,
    phone: parseInt(FormUtils.cleansePhone(state.phone)),
    spouse: state.spouse,
    spouseGender: state.spouse_gender,
    spouseBirthdate:
      state.spouse_year + '-' + state.spouse_month + '-' + state.spouse_day,
    leadAdSource: qp.utm_source ? qp.utm_source.toString() : 'direct',
    leadMedium: qp.utm_medium ? qp.utm_medium.toString() : 'search',
    leadAdCampaign: qp.utm_campaign ? qp.utm_campaign.toString() : '',
    leadAdGroup: qp.adgroupid ? qp.adgroupid.toString() : '',
    leadKeyword: qp.utm_term ? qp.utm_term.toString() : '',
    leadNetwork: qp.network ? qp.network.toString() : '',
    leadAdPosition: qp.adposition ? qp.adposition.toString() : '',
    fbclid: qp.fbclid ? qp.fbclid.toString() : '',
    leadContent: qp.utm_content ? qp.utm_content.toString() : '',
    leadExtension: qp.extensionid ? qp.extensionid.toString() : '',
    leadMatchType: qp.matchtype ? qp.matchtype.toString() : '',
    leadLocation: qp.location ? qp.location.toString() : '',
    leadDevice: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().type
      : '',
    msclkid: qp.msclkid ? qp.msclkid.toString() : '',
    leadPlacement: qp.placement ? qp.placement.toString() : '',
    leadQueryString: qp.querystring ? qp.querystring.toString() : '',
    excludeFromBoberdooAPICallouts: qp.info ? qp.info.toString() : '',
    leadBrowser: FormUtils.getBrowserName(),
    hotjarSession: cookieValues.hotjarSessionCookie,
    leadClientIDGoogle: cookieValues.leadClientIDGoogleCookie,
    activeProspect: !isEmpty(ap)
      ? ap
      : !isEmpty(cookieValues.activeProspect)
      ? cookieValues.activeProspect
      : '',
    journayaLeadID: !isEmpty(jlID)
      ? jlID
      : !isEmpty(cookieValues.journayaLeadID)
      ? cookieValues.journayaLeadID
      : '',
    leadStatus: 'New',
    leadSubStatus: '',
    leadPageID: process.env.GATSBY_SITE_VERSION,
    smsOptIn: state.sms_opt_in,
    osName: FormUtils.getOS(),
    sampleQuote: '',
    matokenID: qp.MATOKENID ? qp.MATOKENID.toString() : '',
    sessionStartTime: new Date().toISOString(),

    leadDeviceBrand: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().brand
      : '',
    leadDeviceName: FormUtils.getDeviceDetails()
      ? FormUtils.getDeviceDetails().model
      : '',
    leadOsVersion: FormUtils.getOSVersion(),
    leadScreenHeight: FormUtils.getWindow()
      ? FormUtils.getWindow().outerHeight
      : '',
    leadScreenWidth: FormUtils.getWindow()
      ? FormUtils.getWindow().outerWidth
      : '',
    leadLandingPage: FormUtils.getDocument()
      ? FormUtils.getDocument().location.origin +
        FormUtils.getDocument().location.pathname
      : '',
    leadReferringUrl:
      FormUtils.getDocument() && FormUtils.getDocument().referrer
        ? new URL(FormUtils.getDocument().referrer).hostname
        : '',
    campaignID: qp.campaignID ? qp.campaignID.toString() : '',
    adgroup_ID: qp.adgroup_ID ? qp.adgroup_ID.toString() : '',
    keywordID: qp.keywordID ? qp.keywordID.toString() : '',
    extensionID: qp.extensionid ? qp.extensionid.toString() : '',

    originalLeadAdSource: cookieValues.originalLeadAdSourceCookie,
    originalLeadMedium: cookieValues.originalLeadMediumCookie,
    originalLeadAdCampaign: cookieValues.originalLeadAdCampaignCookie,
    originalLeadAdGroup: cookieValues.originalLeadAdGroupCookie,
    originalLeadDate: cookieValues.originalLeadDateCookie,
    originalLeadURL: cookieValues.originalLeadURLCookie,
    originalGoogleClientID: cookieValues.originalGoogleClientIDCookie,

    originalLeadNetwork: cookieValues.originalLeadNetwork,
    originalLeadMatchType: cookieValues.originalLeadMatchType,
    originalLeadKeyword: cookieValues.originalLeadKeyword,
    originalLeadQueryString: cookieValues.originalLeadQueryString,
    originalLeadContent: cookieValues.originalLeadContent,
    originalLeadExtension: cookieValues.originalLeadExtension,
    originalLeadDevice: cookieValues.originalLeadDevice,
    originalLeadLocation: cookieValues.originalLeadLocation,
    originalLeadPlacement: cookieValues.originalLeadPlacement,
    originalLeadAdPosition: cookieValues.originalLeadAdPosition,
    originalLeadDeviceBrand: cookieValues.originalLeadDeviceBrand,
    originalLeadDeviceName: cookieValues.originalLeadDeviceName,
    originalLeadOsVersion: cookieValues.originalLeadOsVersion,
    originalLeadScreenHeight: cookieValues.originalLeadScreenHeight,
    originalLeadScreenWidth: cookieValues.originalLeadScreenWidth,
    originalLeadLandingPage: cookieValues.originalLeadLandingPage,
    originalLeadReferringUrl: cookieValues.originalLeadReferringUrl,
    originalLeadIPAddress: cookieValues.originalLeadIPAddress,
    originalLeadBrowser: cookieValues.originalLeadBrowser,
    originalLeadOS: cookieValues.originalLeadOS,
    originalLeadCampaignID: cookieValues.originalLeadCampaignID,
    originalLeadAdGroupID: cookieValues.originalLeadAdGroupID,
    originalLeadKeywordID: cookieValues.originalLeadKeywordID,
    originalLeadExtensionID: cookieValues.originalLeadExtensionID,
    term_id: state.term_id,

    leadBrand: cookieValues.leadBrand,
  };

  // internal methods
  const getLeadSource = () => {
    if (qp.utm_source === 'social' || qp.utm_source === 'fb') {
      return 'Facebook - Online';
    } else if (qp.utm_source === 'excelimpact' || qp.utm_source === 'apollo') {
      return 'Partner - Online';
    } else if (
      (leadData.email && leadData.email.includes('@test.com')) ||
      (leadData.lastName && leadData.lastName.toLowerCase() === 'test')
    ) {
      return 'Test';
    } else {
      return 'MedicareFAQ - Online';
    }
  };

  const getLeadURL = () => {
    if (typeof window !== 'undefined') {
      return (
        window.location.protocol +
        '//' +
        window.location.hostname +
        window.location.pathname
      );
    } else {
      return '';
    }
  };

  const createCookiesForInvoca = (publicIPAddr) => {
    cookies.set('lead_source', getLeadSource(), {
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Source', leadData.leadAdSource, {
      // utm_source
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Medium', leadData.leadMedium, {
      // utm_medium
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Campaign', leadData.leadAdCampaign, {
      // utm_campaign
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Ad-Group', leadData.leadAdGroup, {
      // adgroupid
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Keyword', leadData.leadKeyword, {
      // utm_term
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Content', leadData.leadContent, {
      // utm_content
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Device', leadData.leadDevice, {
      // from formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Client-ID-Google', leadData.leadClientIDGoogle, {
      // leadClientIdgoogle
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Device-Brand', leadData.leadDeviceBrand, {
      // formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Device-Name', leadData.leadDeviceName, {
      // formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_OS-Version', leadData.leadOsVersion, {
      // formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Screen-Height', leadData.leadScreenHeight, {
      // formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Screen-Width', leadData.leadScreenWidth, {
      // formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Landing-Page', leadData.leadLandingPage, {
      // formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: encodeURI,
    });
    cookies.set('lead_URL', getLeadURL(), {
      // ?
      path: '/',
      domain: '.devoted-medicare.com',
      encode: encodeURI,
    });
    cookies.set('lead_Referring-URL', leadData.leadReferringUrl, {
      // ?
      path: '/',
      domain: '.devoted-medicare.com',
      encode: encodeURI,
    });
    cookies.set('lead_IP-Address', publicIPAddr, {
      // ?
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Browser', leadData.leadBrowser, {
      // formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set('lead_Operating-System', leadData.osName, {
      // formUtils
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
    cookies.set(
      'marketing_Phone-Ad-Type',
      leadData.leadAdSource + ' - ' + leadData.leadMedium,
      { path: '/', domain: '.devoted-medicare.com', encode: unescape }
    );
    cookies.set('leadBrand', brandName, {
      path: '/',
      domain: '.devoted-medicare.com',
      encode: unescape,
    });
  };

  const createCookies = (publicIPAddr) => {
    let device = FormUtils.getDeviceDetect();
    if (!('marketing_Original-Lead-Date' in cookies.getAll())) {
      cookies.set(
        'marketing_Original-Lead-Ad-Source',
        qp.utm_source ? qp.utm_source.toString() : 'direct',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Medium',
        qp.utm_medium ? qp.utm_medium.toString() : 'search',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Campaign',
        qp.utm_campaign ? qp.utm_campaign.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Group',
        qp.adgroupid ? qp.adgroupid.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Date', new Date().toISOString(), {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-URL', getLeadURL(), {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Google-Client-ID',
        cookies.get('_ga') ? cookies.get('_ga') : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
    }
    // * Cookies required for Invoca
    // * Additional check needed to set these values for people who already have visited rates but not have these values filled
    if (!('marketing_Original-Lead-Network' in cookies.getAll())) {
      cookies.set('marketing_Original-Lead-Network', leadData.leadNetwork, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Match-Type',
        leadData.leadMatchType,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Keywords', leadData.leadKeyword, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Query-String',
        leadData.leadQueryString,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Content', leadData.leadContent, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Extension', leadData.leadExtension, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Device',
        FormUtils.getDeviceDetails() ? FormUtils.getDeviceDetails().type : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Location', leadData.leadLocation, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set('marketing_Original-Lead-Placement', leadData.leadPlacement, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Ad-Position',
        leadData.leadAdPosition,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Device-Name',
        FormUtils.getDeviceDetails() ? FormUtils.getDeviceDetails().model : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Device-Brand',
        FormUtils.getDeviceDetails() ? FormUtils.getDeviceDetails().brand : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Lead-OS-Version', device.osVersion, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Screen-Height',
        FormUtils.getWindow() ? FormUtils.getWindow().outerHeight : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Screen-Width',
        FormUtils.getWindow() ? FormUtils.getWindow().outerWidth : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Landing-Page',
        FormUtils.getDocument()
          ? FormUtils.getDocument().location.origin +
              FormUtils.getDocument().location.pathname
          : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: encodeURI,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Referring-URL',
        FormUtils.getDocument() && FormUtils.getDocument().referrer
          ? new URL(FormUtils.getDocument().referrer).hostname
          : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: encodeURI,
        }
      );
      cookies.set('marketing_Original-Lead-IP-Address', publicIPAddr, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Lead-Browser',
        leadData.leadBrowser,
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set('marketing_Original-Lead-Operating-System', leadData.osName, {
        path: '/',
        maxAge: maxAge,
        domain: '.devoted-medicare.com',
        encode: unescape,
      });
      cookies.set(
        'marketing_Original-Lead-Campaign-ID',
        qp.campaignID ? qp.campaignID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Ad-Group-ID',
        qp.adgroup_ID ? qp.adgroup_ID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Keyword-ID',
        qp.keywordID ? qp.keywordID.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
      cookies.set(
        'marketing_Original-Lead-Extension-ID',
        qp.extensionid ? qp.extensionid.toString() : '',
        {
          path: '/',
          maxAge: maxAge,
          domain: '.devoted-medicare.com',
          encode: unescape,
        }
      );
    }
  };

  const saveCookieData = () => {
    setCookieValues({
      hotjarSessionCookie: retrieveHotJarUserId(),
      leadClientIDGoogleCookie:
        cookies.get('_ga') !== undefined ? cookies.get('_ga') : '',
      originalLeadAdSourceCookie:
        cookies.get('marketing_Original-Lead-Ad-Source') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Source')
          : '',
      originalLeadMediumCookie:
        cookies.get('marketing_Original-Lead-Medium') !== undefined
          ? cookies.get('marketing_Original-Lead-Medium')
          : '',
      originalLeadAdCampaignCookie:
        cookies.get('marketing_Original-Lead-Ad-Campaign') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Campaign')
          : '',
      originalLeadAdGroupCookie:
        cookies.get('marketing_Original-Lead-Ad-Group') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Group')
          : '',
      originalLeadDateCookie:
        cookies.get('marketing_Original-Lead-Date') !== undefined
          ? cookies.get('marketing_Original-Lead-Date')
          : '',
      originalLeadURLCookie:
        cookies.get('marketing_Original-Lead-URL') !== undefined
          ? cookies.get('marketing_Original-Lead-URL')
          : '',
      originalGoogleClientIDCookie:
        cookies.get('marketing_Original-Google-Client-ID') !== undefined
          ? cookies.get('marketing_Original-Google-Client-ID')
          : '',
      originalLeadNetwork:
        cookies.get('marketing_Original-Lead-Network') !== undefined
          ? cookies.get('marketing_Original-Lead-Network')
          : '',
      originalLeadMatchType:
        cookies.get('marketing_Original-Lead-Match-Type') !== undefined
          ? cookies.get('marketing_Original-Lead-Match-Type')
          : '',
      originalLeadKeyword:
        cookies.get('marketing_Original-Lead-Keywords') !== undefined
          ? cookies.get('marketing_Original-Lead-Keywords')
          : '',
      originalLeadQueryString:
        cookies.get('marketing_Original-Lead-Query-String') !== undefined
          ? cookies.get('marketing_Original-Lead-Query-String')
          : '',
      originalLeadContent:
        cookies.get('marketing_Original-Lead-Content') !== undefined
          ? cookies.get('marketing_Original-Lead-Content')
          : '',
      originalLeadExtension:
        cookies.get('marketing_Original-Lead-Extension') !== undefined
          ? cookies.get('marketing_Original-Lead-Extension')
          : '',
      originalLeadDevice:
        cookies.get('marketing_Original-Lead-Device') !== undefined
          ? cookies.get('marketing_Original-Lead-Device')
          : '',
      originalLeadLocation:
        cookies.get('marketing_Original-Lead-Location') !== undefined
          ? cookies.get('marketing_Original-Lead-Location')
          : '',
      originalLeadPlacement:
        cookies.get('marketing_Original-Lead-Placement') !== undefined
          ? cookies.get('marketing_Original-Lead-Placement')
          : '',
      originalLeadAdPosition:
        cookies.get('marketing_Original-Lead-Ad-Position') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Position')
          : '',
      originalLeadDeviceName:
        cookies.get('marketing_Original-Lead-Device-Name') !== undefined
          ? cookies.get('marketing_Original-Lead-Device-Name')
          : '',
      originalLeadDeviceBrand:
        cookies.get('marketing_Original-Lead-Device-Brand') !== undefined
          ? cookies.get('marketing_Original-Lead-Device-Brand')
          : '',
      originalLeadOsVersion:
        cookies.get('marketing_Original-Lead-Lead-OS-Version') !== undefined
          ? cookies.get('marketing_Original-Lead-Lead-OS-Version')
          : '',
      originalLeadScreenHeight:
        cookies.get('marketing_Original-Lead-Screen-Height') !== undefined
          ? cookies.get('marketing_Original-Lead-Screen-Height')
          : '',
      originalLeadScreenWidth:
        cookies.get('marketing_Original-Lead-Screen-Width') !== undefined
          ? cookies.get('marketing_Original-Lead-Screen-Width')
          : '',
      originalLeadLandingPage:
        cookies.get('marketing_Original-Lead-Landing-Page') !== undefined
          ? cookies.get('marketing_Original-Lead-Landing-Page')
          : '',
      originalLeadReferringUrl:
        cookies.get('marketing_Original-Lead-Referring-URL') !== undefined
          ? cookies.get('marketing_Original-Lead-Referring-URL')
          : '',
      originalLeadIPAddress:
        cookies.get('marketing_Original-Lead-IP-Address') !== undefined
          ? cookies.get('marketing_Original-Lead-IP-Address')
          : '',
      originalLeadBrowser:
        cookies.get('marketing_Original-Lead-Lead-Browser') !== undefined
          ? cookies.get('marketing_Original-Lead-Lead-Browser')
          : '',
      originalLeadOS:
        cookies.get('marketing_Original-Lead-Operating-System') !== undefined
          ? cookies.get('marketing_Original-Lead-Operating-System')
          : '',
      originalLeadCampaignID:
        cookies.get('marketing_Original-Lead-Campaign-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Campaign-ID')
          : '',
      originalLeadAdGroupID:
        cookies.get('marketing_Original-Lead-Ad-Group-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Ad-Group-ID')
          : '',
      originalLeadKeywordID:
        cookies.get('marketing_Original-Lead-Keyword-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Keyword-ID')
          : '',
      originalLeadExtensionID:
        cookies.get('marketing_Original-Lead-Extension-ID') !== undefined
          ? cookies.get('marketing_Original-Lead-Extension-ID')
          : '',
      leadBrand:
        cookies.get('leadBrand') !== undefined
          ? cookies.get('leadBrand')
          : brandName,
    });
  };

  const getLeadLandingPage = () => {
    const leadLandingPage = FormUtils.getDocument()
      ? FormUtils.getDocument().location.origin +
        FormUtils.getDocument().location.pathname
      : '';
    return leadLandingPage;
  };

  const setHomePageDetails = (data) => {
    // Navigation Start to Lead App
    console.log(isMedicareAdvantage, isPDP);

    const leadData_ = {
      postalCode: data.zipCode,
      _gaexp: cookies.get('_gaexp') !== undefined ? cookies.get('_gaexp') : '',
      optimizeleadquality:
        cookies.get('optimizeleadquality') !== undefined
          ? cookies.get('optimizeleadquality')
          : '',
      leadPageID: process.env.GATSBY_SITE_VERSION,
      sessionStartTime: new Date().toISOString(),
      leadBrand:
        cookies.get('leadBrand') !== undefined
          ? cookies.get('leadBrand')
          : brandName,
      termId: data.termId,
      hotjarSession: retrieveHotJarUserId(),
      leadClientIDGoogle:
        cookies.get('_ga') !== undefined ? cookies.get('_ga') : '',
      ...qp,
    };
    console.log('Data ::', leadData_);

    FormUtils.scrollToTop();
    FormUtils.getWindow().onbeforeunload = null;

    let redirectURL_ = ' ';
    let coverageType = isMedicareAdvantage ? '/ma' : isPDP ? '/pdp' : '/ms';
    if (data.isPopUpZipCode) {
      redirectURL_ = process.env.GATSBY_REDIRECT_APP;
      leadData_['type'] = isMedicareAdvantage ? 'ma' : isPDP ? 'pdp' : 'ms';
      leadData_['nxt'] = 'QT-NP';
    } else {
      redirectURL_ = process.env.GATSBY_LEAD_APP_URL;
    }

    redirectURL_ += coverageType;
    const redirectURL = new URL(redirectURL_);
    const demographicsLead = {
      ...leadData_,
      'marketing_Original-Lead-Ad-Source': leadData.originalLeadAdSource,
      'marketing_Original-Lead-Medium': leadData.originalLeadMedium,
      'marketing_Original-Lead-Ad-Campaign': leadData.originalLeadAdCampaign,
      'marketing_Original-Lead-Ad-Group': leadData.originalLeadAdGroup,
      'marketing_Original-Lead-Date': leadData.originalLeadDate,
      'marketing_Original-Lead-URL': leadData.originalLeadURL,
      'marketing_Original-Google-Client-ID': leadData.originalGoogleClientID,
      'marketing_Original-Lead-Network': leadData.originalLeadNetwork,
      'marketing_Original-Lead-Match-Type': leadData.originalLeadMatchType,
      'marketing_Original-Lead-Keywords': leadData.originalLeadKeyword,
      'marketing_Original-Lead-Query-String': leadData.originalLeadQueryString,
      'marketing_Original-Lead-Content': leadData.originalLeadContent,
      'marketing_Original-Lead-Extension': leadData.originalLeadExtension,
      'marketing_Original-Lead-Device': leadData.originalLeadDevice,
      'marketing_Original-Lead-Location': leadData.originalLeadLocation,
      'marketing_Original-Lead-Placement': leadData.originalLeadPlacement,
      'marketing_Original-Lead-Ad-Position': leadData.originalLeadAdPosition,
      'marketing_Original-Lead-Device-Brand': leadData.originalLeadDeviceBrand,
      'marketing_Original-Lead-Device-Name': leadData.originalLeadDeviceName,
      'marketing_Original-Lead-Lead-OS-Version': leadData.originalLeadOsVersion,
      'marketing_Original-Lead-Screen-Height':
        leadData.originalLeadScreenHeight,
      'marketing_Original-Lead-Screen-Width': leadData.originalLeadScreenWidth,
      'marketing_Original-Lead-Landing-Page': leadData.originalLeadLandingPage,
      'marketing_Original-Lead-Referring-URL':
        leadData.originalLeadReferringUrl,
      'marketing_Original-Lead-IP-Address': leadData.originalLeadIPAddress,
      'marketing_Original-Lead-Lead-Browser': leadData.originalLeadBrowser,
      'marketing_Original-Lead-Operating-System': leadData.originalLeadOS,
      'marketing_Original-Lead-Campaign-ID': leadData.originalLeadCampaignID,
      'marketing_Original-Lead-Ad-Group-ID': leadData.originalLeadAdGroupID,
      'marketing_Original-Lead-Keyword-ID': leadData.originalLeadKeywordID,
      'marketing_Original-Lead-Extension-ID': leadData.originalLeadExtensionID,
      leadURL: getLeadURL(),
      leadReferringUrl: leadData.leadReferringUrl,
      leadLandingPage: getLeadLandingPage(),
    };
    console.log('DL ::', demographicsLead);

    const formElement =
      FormUtils.getDocument().getElementById('devoted-zip-code');
    fireEvent(
      'navigate',
      { target: { href: redirectURL_ } },
      { description: 'Devoted Form Submission' }
    );
    formElement.action = redirectURL.href;

    while (formElement.firstChild) {
      formElement.firstChild.remove();
    }

    cookies.get('_ga') !== undefined &&
      PreLeadService.upsertPreLeadObject({
        leadClientIDGoogle: cookies.get('_ga'),
        ...demographicsLead,
      });

    Object.keys(demographicsLead).forEach((leadDataKey) => {
      var inputElement = document.createElement('INPUT');
      inputElement.setAttribute('type', 'hidden');
      inputElement.setAttribute('autocomplete', 'off');
      inputElement.setAttribute('name', leadDataKey);
      inputElement.setAttribute('value', demographicsLead[leadDataKey]);
      formElement.appendChild(inputElement);
    });
    formElement.submit();
  };

  // CRA
  // const { trackCurrentPage } = React.useContext(ActionContext);

  // floating header button in mob screens
  const [isHeaderVisible, setIsHeaderVisible] = React.useState(true);

  React.useEffect(() => {
    // trackCurrentPage('Homepage');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        console.log('intersection observer', entry.isIntersecting);
        setIsHeaderVisible(entry.isIntersecting);
      });
    });
    let target = document.getElementById('page-header');
    observer.observe(target);
    // if (typeof window !== "undefined") {
    //   window.onbeforeunload = function () {
    //     return "Sure you want to leave?";
    //   };
    // }
  }, []);

  React.useEffect(() => {
    if (qpUseEffectAlreadyRun === true) {
      createCookiesForInvoca();
      createCookies();
      saveCookieData();
    } else {
      qpUseEffectAlreadyRun = true;
    }
  }, [qp]);

  React.useEffect(() => {
    if (cookies.get('hotjarId'))
      window.hj('identify', retrieveHotJarUserId(), {
        hotjar_user_id: retrieveHotJarUserId(),
      });

    checkAP();
    checkLeadiD();
  }, [cookies]);

  // React.useEffect(() => {
  //   if (leadData.leadClientIDGoogle) {
  //     PreLeadService.upsertPreLeadObject({
  //       timestamp: new Date().getTime(),
  //       ...leadData,
  //     });
  //   }
  // }, [leadData.leadClientIDGoogle, leadData.journayaLeadID]);

  // Internal Methods Start
  const checkAP = () => {
    const apValue =
      cookies.get('trustedFormId') !== undefined
        ? cookies.get('trustedFormId')
        : '';
    console.log('AP Setting value :: ', apValue);
    setAP(apValue);
  };
  const checkLeadiD = () => {
    const liD =
      cookies.get('externalLeadiD') !== undefined
        ? cookies.get('externalLeadiD')
        : '';
    console.log('External Lead ID Setting value :: ', liD);
    setJLID(liD);
    if (typeof window !== 'undefined' && window.LeadiD) {
      window.LeadiD.snap();
    }
  };

  const retrieveHotJarUserId = () => {
    return cookies.get('hotjarId') &&
      atob(cookies.get('hotjarId')) &&
      JSON.parse(atob(cookies.get('hotjarId'))).id.substring(0, 8)
      ? JSON.parse(atob(cookies.get('hotjarId'))).id.substring(0, 8)
      : '';
  };

  return (
    <main>
      <SEO title='Devoted Medicare' />
      <form id='devoted-zip-code' method='GET' hidden></form>
      <HomePage
        isMedicareAdvantage={isMedicareAdvantage}
        isPDP={isPDP}
        utmTerm={qp.utm_term}
        onSubmit={setHomePageDetails}
      />
    </main>
  );
};

NavigationComponent.propTypes = {
  isMedicareAdvantage: PropTypes.bool,
  isPDP: PropTypes.bool,
};

NavigationComponent.defaultProps = {
  isMedicareAdvantage: false,
  isPDP: false,
};

export default NavigationComponent;
