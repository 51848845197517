import { ErrorManager } from '@foxsenseinnovations/vigil-sdk-js';
import axios from 'axios';
import axiosRetry from 'axios-retry';

const pattern = new RegExp(/\b\d{5}\b/);

const isValidZipCode = (zipCode) => {
  if (zipCode) return zipCode.length === 5 && pattern.test(zipCode);
  return false;
};

const getCountyForZip = (zipCode) => {
  return new Promise((resolve, reject) => {
    if (zipCode) {
      axiosRetry(axios, { retries: 3 });
      axios
        .get(
          `https://us-zipcode.api.smartystreets.com/lookup?zipcode=${zipCode}&key=27259743547683152`
        )
        .then((response) => {
          if (response.data[0].status !== 'invalid_zipcode') {
            resolve(response.data[0]);
          } else throw response;
        })
        .catch((error) => {
          if (error.data && error.data[0].status === 'invalid_zipcode') {
            console.error(
              `Error in validating Zipcode with status code ${error.data[0].status}`
            );
          }
          else {
            ErrorManager.captureException(
              {
                ...error,
                message: `${error.message} for the zipcode ${zipCode}`,
                name: 'Smarty Streets Axios Error in Devoted',
              },
              { context: {}, tags: [] }
            );
          }
          console.error('Invalid Zipcode ', error.data);
          reject(error);
        });
    } else reject('zipCode is empty');
  });
};

const ZipCodeService = {
  isValidZipCode,
  getCountyForZip,
};

export default ZipCodeService;
