import ArrowButton from './ArrowButtonDiv';
import CarouselItem from './CarouselItem';
import React from 'react';
import person1 from '../../images/person1.png';
import person3 from '../../images/person3.png';
import person4 from '../../images/person4.png';

const contents = [
  '"I had done my medicare homework but having the agent go through things with me was comforting. When coming to Medicare it\'s confusing but the team gave me the choices that made things seamless. They also gave me a few follow up calls making me know I made the right call. Thanks to the agent and team."',
  '"Trying to understand the process of Medicare A & B, Supplemental, and Advantage plans can get so overwhelming. My agent explained in detail to help me understand my options. A lot of stress was relieved today. I will be ready when my plan kicks in. Thank You!"',
  '"Friendly people to do business with. Called me back at a time when I could answer the phone and talk when not at work. Listened to my questions, gave me some options and helped me pick the best policies for me and my budget."',
];
const images = [person1, person3, person4];
const names = ['Brad Williams', 'Brad B.', 'Eric L.'];
const ages = [];
const ratings = [5, 5, 5];

export default function ReviewSection() {
  return (
    <div
      id='reviewSectionCarousel'
      className='carousel slide relative px-10 flex md:px-20 lg:px-24 xl:px-40 2xl:px-48 md:h-96 md:mb-40'
      data-bs-ride='carousel'
      data-bs-interval='10000'
    >
      <ArrowButton typeNext={false} />

      <div className='flex flex-col mb-10 md:mb-16 lg:my-16 h-full'>
        <div className='carousel-inner w-full overflow-hidden'>
          <div className='carousel-item active relative float-left w-full'>
            <CarouselItem
              image={images[0]}
              name={names[0]}
              age={ages[0]}
              rating={ratings[0]}
              content={contents[0]}
            />
          </div>
          <div className='carousel-item relative float-left w-full'>
            <CarouselItem
              image={images[1]}
              name={names[1]}
              age={ages[1]}
              rating={ratings[1]}
              content={contents[1]}
            />
          </div>
          <div className='carousel-item relative float-left w-full'>
            <CarouselItem
              image={images[2]}
              name={names[2]}
              age={ages[2]}
              rating={ratings[2]}
              content={contents[2]}
            />
          </div>
        </div>
        <div className='carousel-indicators w-1/2 sm:w-1/3 mx-auto flex justify-center mb-4 '>
          <button
            type='button'
            data-bs-target='#reviewSectionCarousel'
            data-bs-slide-to='0'
            className='active'
            aria-current='true'
            aria-label='Slide 1'
            tabIndex='-1'
          ></button>
          <button
            type='button'
            data-bs-target='#reviewSectionCarousel'
            data-bs-slide-to='1'
            aria-label='Slide 2'
            tabIndex='-1'
          ></button>
          <button
            type='button'
            data-bs-target='#reviewSectionCarousel'
            data-bs-slide-to='2'
            aria-label='Slide 3'
            tabIndex='-1'
          ></button>
        </div>
      </div>

      <ArrowButton typeNext={true} />
    </div>
  );
}
