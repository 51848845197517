import axios from 'axios';
const upsertPreLeadObject = (data) => {
  return new Promise((resolve, reject) => {
    if (data.leadClientIDGoogle) {
      axios({
        method: 'post',
        url: process.env.GATSBY_PRE_LEAD_LAMBDA_URL,
        data,
      })
        .then(function (data) {
          resolve(data);
        })
        .catch(function (error) {
          reject(error);
        });
    } else reject('Google Client Id is empty');
  });
};
const PreLeadService = {
  upsertPreLeadObject,
};
export default PreLeadService;
