import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import React, { useEffect, useRef } from 'react';

export default function ArrowButton({ className, children, typeNext }) {
  const myRefName = useRef(2);
  useEffect(() => {
    setInterval(() => {
      typeNext && myRefName.current.click();
    }, 10000);
  }, []);
  return (
    <div className=' flex items-center justify-center '>
      <button
        ref={myRefName}
        className={`w-32 md:basis-2/12 text-center p-2 lg:basis-[10%] rounded-full bg-primary_shade_light2  hidden sm:block ${className}  max-w-[50px] hover:bg-primary_shade_light1 transition duration-250`}
        style={{ flexGrow: '1' }}
        data-bs-target='#reviewSectionCarousel'
        data-bs-slide={typeNext ? 'next' : 'prev'}
        aria-label={typeNext ? 'next' : 'previous'}
      >
        {typeNext ? (
          <HiChevronRight className='h-full w-full' />
        ) : (
          <HiChevronLeft className='h-full w-full' />
        )}
      </button>
    </div>
  );
}
