import React from 'react';

const StepNumber = ({ number, className }) => {
  return (
    <div
      className={`rounded-full bg-primary_shade_light3 w-7 h-7 xl:w-10 xl:h-10 text-center pt-0.5 xl:pt-2 ${className} `}
    >
      {number}
    </div>
  );
};

export default StepNumber;
