import UtmTermConstants from '../constants/UtmTermConstants';

export const ContentUtils = {
  getDynamicTexts: (utmTerm) => {
    let plan = '',
      coverage = 'Medicare Advantage',
      planKey = '',
      coverageKey = '';

    if (utmTerm) {
      utmTerm = String(utmTerm);
      // Check for Plans
      Object.keys(UtmTermConstants.plans).some((key) => {
        if (
          utmTerm.toLowerCase().match(`\\b${String(key).toLowerCase()}\\b`) !==
          null
        ) {
          plan = UtmTermConstants.plans[key];
          planKey = key;
          return true;
        }
        return false;
      });
      // Check for Coverage
      Object.keys(UtmTermConstants.coverages).some((key) => {
        if (
          utmTerm.toLowerCase().match(`\\b${String(key).toLowerCase()}\\b`) !==
          null
        ) {
          coverage = UtmTermConstants.coverages[key];
          coverageKey = key;
          return true;
        }
        return false;
      });
    }

    return {
      plan: plan,
      coverage: coverage,
      title: `Find the ${(plan ? plan + ' ' : '') + coverage} ${
        plan ? '' : 'Plan '
      } that/s right for you in in`,
      planKey: planKey,
      coverageKey: coverageKey,
    };
  },

  /**
   * To know what term was used to trigger the image and text. The convention is: carrier_plan_coverage
   * Generic means no carrier or no plan
   * e.g.,
   * devoted_plan f_medigap
   */
  getTermIDForHomepage: (planKey, coverageKey) => {
    let term_id = UtmTermConstants.GENERIC_TERM;

    // Adding space between carrier and plan
    term_id += '_';
    if (planKey) term_id += planKey;
    else term_id += UtmTermConstants.GENERIC_TERM;

    // Adding space between plan and coverage
    term_id += '_';
    if (coverageKey) term_id += coverageKey;
    else term_id += UtmTermConstants.GENERIC_TERM;

    return term_id;
  },
};
