import React from 'react';

export default function ImageWithCaptionSection({ className }) {
  return (
    <section className='max-h-max bg-pexels_rodnae text-center mt-24  bg-right bg-cover flex flex-col justify-center pt-10 pb-10 md:pt-10 md:pb-10 '>
      <div className='flex flex-col justify-center'>
        <span className='text-primary font-bold text-6xl md:text-7xl'>
          140,000
        </span>
        <br />
        <span className='md:text-xl'>people trust Devoted Health.*</span>
      </div>
    </section>
  );
}
