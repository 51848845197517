import { ContentUtils } from "../../utils/ContentUtils";
import React from "react";
import ZipCodeInputDiv from "./ZipCodeInputDiv";

export default function HeroSectionContent({ onSubmit, utmTerm }) {
  const [content, setContent] = React.useState({
    title: "",
    plan: "",
    coverage: "",
  });
  React.useEffect(() => {
    const { title, plan, coverage } = ContentUtils.getDynamicTexts(utmTerm);
    setContent({ title, plan, coverage });
  }, [utmTerm]);
  return (
    <section className="flex flex-col justify-evenly gap-8">
      <div className="flex flex-col justify-evenly gap-4">
        <span className=" lg:text-5xl text-2xl  lg:mt-20  lg:leading-[4rem] xl:pr-24">
          Find the{" "}
          <span className="text-primary font-bold">
            {(content.plan ? content.plan + " " : "") + content.coverage}{" "}
            {content.plan ? "" : "Plan "}
          </span>
          that’s right for you in{" "}
          <span className="font-bold">3 simple steps</span>
        </span>
        <span className="lg:pr-32 lg:text-xl xl:text-2xl font-light">
          Compare & save up to hundreds of dollars on your Medicare costs
        </span>
      </div>
      <div className="lg:pb-20">
        <div className="h-20 md:h-32 pr-5 xl:pr-10 2xl:pr-28">
          <ZipCodeInputDiv onSubmit={onSubmit} textVariant={true} />
        </div>
      </div>
    </section>
  );
}
