import 'tw-elements';

import * as React from 'react';

import HeroSection from './home/HeroSection';
import ImageWithCaptionSection from './home/ImageWithCaptionSection';
import Layout from './PageLayout';
import ReviewSection from './home/ReviewSection';
import StepsSection from './home/StepsSection';
import VideoSection from './home/VideoSection';
import { useExitIntent } from 'use-exit-intent';

const HomePage = ({ isMedicareAdvantage, isPDP, onSubmit, utmTerm }) => {
  const [isSubmitClicked, setIsSubmitClicked] = React.useState(false);
  const { resetState } = useExitIntent();

  React.useEffect(() => {
    if (isSubmitClicked) resetState();
  }, [isSubmitClicked]);

  const onZipCodeSubmit = (zipcode, availableCounties, stateAbbrv, termId) => {
    const data = {
      zipCode: zipcode,
      availableCounties,
      stateAbbrv,
      termId,
    };
    console.log('---->', data);
    setIsSubmitClicked(true);
    onSubmit(data);
  };
  return (
    <Layout>
      <HeroSection
        onSubmit={onZipCodeSubmit}
        utmTerm={utmTerm}
        isMedicareAdvantage={isMedicareAdvantage}
        isPDP={isPDP}
      />
      <StepsSection onSubmit={onZipCodeSubmit} />
      <ImageWithCaptionSection />
      <ReviewSection />
      <VideoSection />
    </Layout>
  );
};
export default HomePage;
