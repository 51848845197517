import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { ContentUtils } from '../../utils/ContentUtils';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { HiChevronRight } from 'react-icons/hi';
import ZipCodeService from '../../services/ZipCodeService';

import { fireEvent } from '../../cra';
import { getSearchParams } from 'gatsby-query-params';

export default function ZipCodeInputDiv({ textVariant, className, onSubmit }) {
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [county, setCounty] = useState('');
  const [stateAbbrv, setStateAbbrv] = useState('');
  const [availableCounties, setAvailableCounties] = useState([]);
  const [termId, setTermId] = useState('');
  const qp = getSearchParams();
  const [isValidZipCode, setIsValidZipCode] = useState(false);

  useEffect(() => {
    let utmTerm = qp.utm_term ? qp.utm_term : '';
    const { planKey, coverageKey } = ContentUtils.getDynamicTexts(utmTerm);
    let termId = ContentUtils.getTermIDForHomepage(planKey, coverageKey);
    setTermId(termId);
  }, [qp]);

  useEffect(() => {
    setIsValidZipCode(false);
    if (zipCode.length === 5) {
      setIsLoading(true);
      ZipCodeService.isValidZipCode(zipCode) &&
        ZipCodeService.getCountyForZip(zipCode)
          .then((response) => {
            setIsValidZipCode(true);
            if (response.zipcodes && response.zipcodes.length > 0) {
              let countyObjects = [
                {
                  name: response.zipcodes[0].county_name,
                  fips: response.zipcodes[0].county_fips,
                },
              ];
              if (response.zipcodes[0].alternate_counties) {
                response.zipcodes[0].alternate_counties.forEach((county) => {
                  countyObjects.push({
                    name: county.county_name,
                    fips: county.county_fips,
                  });
                });
              }
              const county =
                countyObjects.length === 1 ? countyObjects[0].fips : '';
              setAvailableCounties(countyObjects);
              setStateAbbrv(response.city_states[0].state_abbreviation);
              setCounty(county);
            } else {
              throw response;
            }
          })
          .catch(() => {
            setIsValid(false);
            setIsValidZipCode(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
  }, [zipCode]);

  const handleChange = (event) => {
    !isValid && setIsValid(true);
    setIsLoading(() => false);
    event.target.value.length < 6 && setZipCode(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    zipCode.length !== 5 && setIsValid(false);

    if (zipCode.length === 5 && isValid) {
      fireEvent('zipSubmit', e, {
        value: zipCode,
        description: 'Submitted Zip Code',
      });
      setIsLoading(() => true);
      setZipCode('');
      onSubmit(zipCode, availableCounties, stateAbbrv, termId);
    }
  };

  return (
    <form
      id='zipcode-form'
      className={`flex flex-col items-start h-full md:-mb-[80px] -mb-[40px] `}
    >
      <div
        className={`block relative w-full md:placeholder:text-xl md:text-lg text-xs text-gray-900  h-full rounded-full  basis-1/2`}
      >
        <span
          className={`absolute z-50 text-red-800 ${
            isValid ? 'invisible' : 'visible'
          } ${
            textVariant
              ? 'left-[20px] top-[45px]  md:top-[65px]'
              : 'text-xs xl:text-base left-[0px] top-[45px]  md:top-[70px] lg:left-[10px] xl:left-[15px]'
          }`}
        >
          Please enter a valid zipcode
        </span>
        <input
          className={`px-2.5 h-full ${!isValid && 'border-red-500'} ${
            textVariant ? 'lg:pl-12 md:pl-12' : 'lg:pl-8 md:pl-8'
          } py-2 md:py-0.5 pl-8   pr-10 rounded-full border border-gray-300 w-full`}
          placeholder='ZIP Code'
          maxLength='5'
          style={{
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2) inset',
            fontSize: '16px',
          }}
          required
          onChange={handleChange}
          aria-label='enter zip code'
          value={zipCode}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onBlur={(e) => {
            fireEvent('textInput', e, {
              label: 'Devoted Zip Code',
              description: 'User Zip Input',
            });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.target.blur();
              if (isValidZipCode) handleSubmit(e);
            }
          }}
        />
      </div>
      <div
        className={`flex items-center  justify-between w-full basis-1/2   h-full `}
      >
        <FaMapMarkerAlt
          color='grey'
          className={`w-4 h-4 bottom-[40px] md:bottom-[64px] relative  ${
            textVariant ? 'md:h-6 md:pl-3 lg:h-7' : 'md:h-4 md:pl-0'
          }  md:w-6  min-w-[40px]`}
        />
        <input
          type='submit'
          hidden
          aria-label='enter zip code to compare plans'
        />
        <button
          type='submit'
          className={`flex  bottom-[40px] md:bottom-[64px] transition duration-500 relative ${className}  ${
            textVariant ? 'pl-2 md:px-2 md:pl-4   md:h-16' : 'w-10 md:w-16'
          } items-center whitespace-nowrap justify-evenly text-xs font-medium  text-white rounded-full border h-full bg-primary_linear_gradient hover:bg-primary_linear_gradient2
           md:text-lg`}
          onClick={handleSubmit}
          disabled={isLoading || !isValidZipCode || zipCode.length < 5}
          aria-label='enter zip code to compare plans'
          id='Compare Plans'
        >
          {isLoading && (
            <div className='h-8 w-8 mr-2 '>
              <CircularProgress size='inherit' style={{ color: 'white' }} />
            </div>
          )}
          {textVariant && <span id='Compare Plans'>Compare plans</span>}
          <HiChevronRight
            id='Compare Plans'
            className={`${!textVariant & isLoading && 'hidden'} h-8 w-8`}
          />
        </button>
      </div>
    </form>
  );
}
