import { browserName, deviceDetect } from 'react-device-detect';

import DeviceDetector from 'device-detector-js';

const deviceDetector = new DeviceDetector();
const isBrowser = typeof window !== 'undefined';
const FormUtils = {
  cleansePhone: (num) => {
    return num.replace(/[^0-9]/g, '');
  },

  getURL: () => {
    return typeof window !== 'undefined' ? window.location.href : '';
  },

  getDeviceDetect: () => {
    return deviceDetect();
  },

  getOS: () => {
    let device = deviceDetect();
    return device.osName ? device.osName : '';
  },

  getOSVersion: () => {
    let device = deviceDetect();
    return device.osVersion ? device.osVersion : '';
  },

  getBrowserName: () => {
    return browserName;
  },

  getDeviceDetails: () => {
    if (isBrowser) {
      const deviceDetails = deviceDetector.parse(deviceDetect().userAgent);
      return deviceDetails.device;
    }
    return {};
  },

  getWindow: () => {
    if (isBrowser) {
      return global.window;
    }
    return null;
  },

  getDocument: () => {
    if (isBrowser) {
      return global.document;
    }
    return null;
  },

  getAge: (dateString) => {
    let today = new Date();
    let birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },

  getCurrentDate: () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0');
    let yyyy = today.getFullYear();
    let h = today.getHours();
    let m = today.getMinutes();
    let s = today.getSeconds();

    return mm + '/' + dd + '/' + yyyy + ' - ' + h + ':' + m + ':' + s;
  },

  scrollToTop: () => {
    if (global.window) global.window.scrollTo(0, 0);
  },
};

export default FormUtils;
