import BadgeIcon from "../../images/badge.svg";
import CheckCircleIcon from "../../images/check_circle.svg";
import MapPinIcon from "../../images/mappin.svg";
import React from "react";
import Step from "./StepComponent";
import StepLine from "./StepLine";
import StepNumber from "./StepNumber";
import ZipCodeInputDivNew from "./ZipCodeInputDiv2";

const icons = [
  <img src={MapPinIcon} alt="zip code icon" />,
  <img src={BadgeIcon} alt="badge icon" />,
  <img src={CheckCircleIcon} alt="check icon" />,
];

const stepClassName =
  "col-span-4 md:h-[26rem] lg:h-[32rem] xl:h-[36rem] 2xl:h-[32rem]";

const StepsSection = ({ onSubmit }) => {
  return (
    <div className="  ">
      <div className="flex sm:hidden pr-14 -my-10 -mb-20 ">
        <div className="flex flex-col my-72 ml-8 justify-around items-center gap-10 ">
          <StepNumber number={1} />
          <StepLine />
          <StepNumber number={2} />
          <StepLine />
          <StepNumber number={3} />
        </div>
        <div className=" flex flex-col  justify-center align-center bg-primary_shade_light1 items-center rounded-[50px] overflow-hidden ml-10  my-20 shadow-md">
          <Step
            title="Enter your ZIP code"
            bg_color="bg-primary_shade_light1"
            icon={icons[0]}
            number={1}
            className=""
          >
            Different locations have different Medicare plans. This helps us
            find all the plans in your area.
            <div className="mt-8 h-8 px-4 ">
              <ZipCodeInputDivNew textVariant={false} onSubmit={onSubmit} />
            </div>
          </Step>
          <Step
            title="Tell us a bit about yourself"
            bg_color="bg-primary_shade_light2"
            icon={icons[1]}
            number={2}
            clas
          >
            A few factors can determine your premium, like location. We only use
            this information to provide the most accurate comparison and find
            the right plan for you.
          </Step>
          <Step
            title="Discover your plan options"
            bg_color="bg-white"
            icon={icons[2]}
            number={3}
          >
            Our Medicare software reviews your information to show the best
            plans for you online.
            <br />
            <br />
            You’ll be able to browse through the plans in detail and assess your
            savings. <span className="font-bold">It’s 100% free!</span>
          </Step>
        </div>
      </div>

      <div className="hidden sm:grid z-10 mt-10 shadow-md grid-cols-12 gap-y-5 justify-center items-center mb-10 sm:pt-4 md:mt-8 lg:mx-20 xl:mx-36 bg-step_section rounded-3xl mx-4 relative lg:-mb-20 lg:-translate-y-24 ">
        <div className="flex justify-around items-center col-start-2 col-span-10  mx-4 xl:mx-7 md:mt-6">
          <StepNumber number={1} className="" />
          <StepLine className="" />
          <StepNumber number={2} className="" />
          <StepLine className="" />
          <StepNumber number={3} className="" />
        </div>
        <div className={stepClassName}>
          <Step
            title="Enter your ZIP code"
            icon={icons[0]}
            number={1}
            className=" w-full "
          >
            Different locations have different Medicare plans. This helps us
            find all the plans in your area.
            <div className="my-10  mt-8 h-8 lg:h-12 ">
              <ZipCodeInputDivNew textVariant={false} onSubmit={onSubmit} />
            </div>
          </Step>
        </div>
        <div className={stepClassName}>
          <Step
            title="Tell us a bit about yourself"
            icon={icons[1]}
            number={2}
            clas
          >
            A few factors can determine your premium, like location. We only use
            this information to provide the most accurate comparison and find
            the right plan for you. 
          </Step>
        </div>
        <div className={stepClassName}>
          <Step title="Discover your plan options" icon={icons[2]} number={3}>
            Our Medicare software reviews your information to show the best
            plans for you online.
            <br />
            <br />
            You’ll be able to browse through the plans in detail and assess your
            savings. <span className="font-bold">It’s 100% free!</span>
          </Step>
        </div>
      </div>
    </div>
  );
};

export default StepsSection;
