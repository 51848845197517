import React from 'react';
// import { fireEvent } from '../cra';

export default function Footer() {
  return (
    <footer className='flex flex-col py-4 justify-evenly items-center bg-primary'>
      <section className='text-white text-center font-light text-xs px-8 sm:px-14 mt-4'>
        ©{' '}
        <a
          href='https://eliteinsurancepartners.com/'
          target='_blank'
          rel='noopener noreferrer'
          className='underline hover:no-underline hover:text-gray-200 transition duration-250'
          // onClick={(e) => {
          //   fireEvent('navigate', e, {
          //     value: e.target.value,
          //     description: 'EIP Official Site Link',
          //   });
          // }}
        >
          {new Date().getFullYear() + ' '} Elite Insurance Partners.
        </a>{' '}
        All Rights Reserved.
        <br />
        <br />
        Owned by: Elite Insurance Partners LLC. This website is not connected
        with the federal government or the federal Medicare program. The purpose
        of this website is the solicitation of insurance. We do not offer every
        plan available in your area. Currently we represent 1 organization which
        offer 97 products in your area. Please contact Medicare.gov or
        1-800-MEDICARE or your local State Health Insurance Program to get
        information on all of your options.
        <br />
        <br />
        <span
          className='cursor-pointer hover:underline text-sm'
          tabIndex='0'
          role='button'
          onClick={(event) => {
            // fireEvent('click', event, {
            //   description:
            //     'navigate to https://eliteinsurancepartners.com/legal-disclosure/ from footer link',
            // });
            window.open('https://eliteinsurancepartners.com/legal-disclosure/');
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // fireEvent('click', event, {
              //   description:
              //     'navigate to https://eliteinsurancepartners.com/legal-disclosure/ from footer link',
              // });
              window.open(
                'https://eliteinsurancepartners.com/legal-disclosure/'
              );
            }
          }}
        >
          Legal Disclosure
        </span>{' '}
        &nbsp; | &nbsp;
        <span
          className='cursor-pointer hover:underline text-sm'
          tabIndex='0'
          role='button'
          onClick={(event) => {
            // fireEvent('click', event, {
            //   description:
            //     'navigate to https://eliteinsurancepartners.com/terms-and-conditions/ from footer link',
            // });
            window.open(
              'https://eliteinsurancepartners.com/terms-and-conditions/'
            );
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // fireEvent('click', event, {
              //   description:
              //     'navigate to https://eliteinsurancepartners.com/terms-and-conditions/ from footer link',
              // });
              window.open(
                'https://eliteinsurancepartners.com/terms-and-conditions/'
              );
            }
          }}
        >
          Terms of Use
        </span>{' '}
        &nbsp; | &nbsp;
        <span
          className='cursor-pointer hover:underline text-sm'
          tabIndex='0'
          role='button'
          onClick={(event) => {
            // fireEvent('click', event, {
            //   description:
            //     'navigate to https://eliteinsurancepartners.com/privacy-policy/ from footer link',
            // });
            window.open('https://eliteinsurancepartners.com/privacy-policy/');
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              // fireEvent('click', event, {
              //   description:
              //     'navigate to https://eliteinsurancepartners.com/privacy-policy/ from footer link',
              // });
              window.open('https://eliteinsurancepartners.com/privacy-policy/');
            }
          }}
        >
          Privacy Policy
        </span>
        <br />
        <br />
        <p
          className='text-white'
          style={{ fontSize: '12px', fontWeight: '300' }}
        >
          {' '}
          MULTI-PLAN_DevotedHealth-Medicare.com_M{' '}
        </p>
      </section>
    </footer>
  );
}
