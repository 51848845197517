import { MdLocalPhone } from 'react-icons/md';
import PropTypes from 'prop-types';
import React from 'react';
import { fireEvent } from '../../cra';

const ContactUsButton = ({ isVisible }) => {
  return (
    <div className='header-container'>
      <a
        href='tel:+11234567890'
        className={`fixed z-[999] ${
          isVisible ? '' : 'top-14'
        }  right-10 self-center phone-number-fade mr-[-30px]`}
        target='_blank'
        rel='noopener noreferrer'
        onClick={(e) => {
          fireEvent('initiateCall', e, {
            description: `Main Homepage CTA Button - Request Callback on +11234567890`,
            value: '+11234567890',
          });
        }}
      >
        <div
          className='flex bg-white h-9 w-9 sm:w-auto lg:h-full lg:w-full items-center justify-evenly border-solid border-2 border-primary text-primary hover:bg-primary hover:text-white transition duration-250 rounded-full py-2 px-2 sm:px-3 phone-container'
          style={{ 'hover-text': 'white !important' }}
        >
          <MdLocalPhone className='h-6 w-6' />
          <div className='hidden sm:flex pl-4 pr-2'>(888) 379-9437</div>
        </div>
      </a>
    </div>
  );
};

ContactUsButton.propTypes = {
  isVisible: PropTypes.bool,
};

ContactUsButton.defaultProps = {
  isVisible: false,
};

export default ContactUsButton;
