import 'tw-elements';

import Banner from './home/BannerComponent';
import Footer from './PageFooter';
import Header from './PageHeader';
import React from 'react';

export default function Layout({ children }) {
  return (
    <main className='layout'>
      <Banner />
      <Header />
      <>{children}</>
      <Footer />
    </main>
  );
}
