import React from 'react';

const Step = ({ title, children, bg_color, icon, number, className }) => {
  const commonDivProps =
    'lg:mx-6 sm:mx-5 text-center lg:text-base xl:text-xl font-light';
  return (
    <div
      className={`flex flex-col gap-3 md:gap-0  ${bg_color} items-center justify-evenly sm:justify-start overflow-visible basis-1/3 sm:pt-5 md:pb-10 py-5  lg:h-[36rem]  sm:px-10 ${className} `}
    >
      <div className='bg-primary h-20 w-20 rounded-3xl flex items-center justify-center text-white p-4'>
        {icon}
      </div>
      <h1 className='text-lg font-semibold text-center mx-12  sm:mx-5 lg:text-xl xl:text-2xl lg:mx-5 pt-2'>
        {title}
      </h1>
      {title == 'Enter your ZIP code' ? (
        <div>
          <div className={'text-xs mx-10 ' + commonDivProps}>{children[0]}</div>
          <div className={'text-xs ' + commonDivProps}>{children[1]}</div>
        </div>
      ) : (
        <div className={'text-xs mx-10 ' + commonDivProps}>{children}</div>
      )}
    </div>
  );
};

export default Step;
