import { MdLocalPhone } from 'react-icons/md';
import React from 'react';
import { fireEvent } from '../../cra';

const VideoSection = () => {
  return (
    <div className='relative rounded-3xl  overflow-hidden bg-primary xl:my-16 my-10 mx-6 lg:w-2/3 lg:mx-auto px-6 text-center flex flex-col py-10 lg:py-14 xl:py-20 md:px-20 gap-y-4 grid-rows-3  md:gap-y-6 xl:gap-y-12 justify-center items-center text-white  sm:basis-3/5 basis-2/5 lg:basis-3/5 min-h-[40%] lg:mb-20 lg:mt-20  '>
      <span className='font-semibold lg:text-xl xl:text-3xl 2xl:text-4xl'>
        Have questions?
      </span>

      <div className='text-xs font-light lg:text-base xl:text-xl  md:px-10 lg:px-16 xl:px-24'>
        Request a call back from one of our licensed experts, and get answers to
        all your Medicare questions for FREE.
        <br />
      </div>
      <a
        href='tel:+11234567890'
        target='_blank'
        rel='noopener noreferrer'
        onClick={(e) => {
          fireEvent('initiateCall', e, {
            label: 'Connect with a Devoted Health expert',
            description: `Request Callback on +11234567890`,
            value: '+11234567890',
          });
        }}
      >
        <div className='flex items-center border rounded-full py-4 px-4 mx-2 md:mx-10 lg:mx-0 xl:mx-24 lg:px-10 bg-white hover:bg-primary_shade_light2 transition duration-250 text-primary text-xs md:text-base xl:text-lg font-semibold '>
          <MdLocalPhone className='h-6 w-6 mr-2' />
          Connect with a Devoted Health expert
        </div>
      </a>
    </div>
  );
};

export default VideoSection;
