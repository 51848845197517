import React from 'react';

export default function Banner({ className }) {
  return (
    <section
      role='banner'
      className={`bg-primary sticky top-0 z-20 text-white text-xs sm:text-xs text-center items-center justify-center py-2 px-2 ${className}`}
    >
      This website is run by Elite Insurance Partners, licensed to sell Devoted
      Medicare products
    </section>
  );
}
