import React, { useEffect, useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { HiChevronRight } from 'react-icons/hi';
import ZipCodeService from '../../services/ZipCodeService';

import { fireEvent } from '../../cra';

export default function ZipCodeInputDivNew({
  textVariant,
  className,
  onSubmit,
}) {
  const [isValid, setIsValid] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [zipCode, setZipCode] = useState('');
  const [county, setCounty] = useState('');
  const [stateAbbrv, setStateAbbrv] = useState('');
  const [availableCounties, setAvailableCounties] = useState([]);
  const [isValidZipCode, setIsValidZipCode] = useState(false);

  useEffect(() => {
    setIsValidZipCode(false);
    if (zipCode.length === 5) {
      setIsLoading(true);
      ZipCodeService.isValidZipCode(zipCode) &&
        ZipCodeService.getCountyForZip(zipCode)
          .then((response) => {
            setIsValidZipCode(true);
            if (response.zipcodes && response.zipcodes.length > 0) {
              let countyObjects = [
                {
                  name: response.zipcodes[0].county_name,
                  fips: response.zipcodes[0].county_fips,
                },
              ];
              if (response.zipcodes[0].alternate_counties) {
                response.zipcodes[0].alternate_counties.forEach((county) => {
                  countyObjects.push({
                    name: county.county_name,
                    fips: county.county_fips,
                  });
                });
              }
              const county =
                countyObjects.length === 1 ? countyObjects[0].fips : '';
              setAvailableCounties(countyObjects);
              setStateAbbrv(response.city_states[0].state_abbreviation);
              setCounty(county);
            } else {
              throw response;
            }
          })
          .catch(() => {
            setIsValid(false);
            setIsValidZipCode(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
    }
  }, [zipCode]);

  const handleChange = (event) => {
    !isValid && setIsValid(true);
    setIsLoading(() => false);
    event.target.value.length < 6 && setZipCode(event.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    zipCode.length !== 5 && setIsValid(false);
    if (zipCode.length === 5 && isValid) {
      fireEvent('zipSubmit', e, {
        value: zipCode,
        description: 'Submitted Zip Code',
      });
      setIsLoading(() => true);
      setZipCode('');
      onSubmit(zipCode, availableCounties, stateAbbrv);
    }
  };

  return (
    <form
      id='zipcode-form-steps'
      className={`flex  relative flex-col items-start  w-full h-full md:-mb-[80px] -mb-[40px] `}
    >
      <div
        className={`block   w-full  md:text-md lg:text-lg text-xs text-gray-900  h-full rounded-full `}
      >
        <span
          className={`absolute z-50 left-2 lg:left-0 top-8 lg:top-12 text-red-800 ${
            isValid ? 'invisible' : 'visible'
          } `}
        >
          Please enter a valid zipcode
        </span>
        <input
          className={`px-2.5 h-full ${
            !isValid && 'border-red-500'
          } py-2 md:py-0.5 pl-8   pr-10 rounded-full border border-gray-300 w-full`}
          placeholder='ZIP Code'
          maxLength='5'
          style={{
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2) inset',
            fontSize: '16px',
          }}
          required
          onChange={handleChange}
          value={zipCode}
          aria-label='enter zip code'
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onBlur={(e) => {
            fireEvent('textInput', e, {
              label: 'Devoted Zip Code',
              description: 'User Zip Input',
            });
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.target.blur();
              if (isValidZipCode) handleSubmit(e);
            }
          }}
        />
      </div>

      <FaMapMarkerAlt
        color='grey'
        className={`w-4 h-4 top-2 lg:top-4 md:bottom-[64px] absolute
           md:w-6  min-w-[40px]`}
      />
      <button
        type='submit'
        className={`flex top-0 right-0 transition duration-500 absolute ${className} items-center whitespace-nowrap justify-evenly text-xs font-medium  text-white rounded-full border h-full aspect-square bg-primary_linear_gradient hover:bg-primary_linear_gradient2
         md:text-lg`}
        onClick={handleSubmit}
        disabled={isLoading || !isValidZipCode || zipCode.length < 5}
        aria-label='submit zip to compare plans'
        id='Compare Plans 2'
      >
        {isLoading && (
          <div className='h-full aspect-square '>
            <CircularProgress size='inherit' style={{ color: 'white' }} />
          </div>
        )}
        {textVariant && <span id='Compare Plans 2'>Compare plans</span>}
        <HiChevronRight
          id='Compare Plans 2'
          className={`${!textVariant & isLoading && 'hidden'} h-8 w-8`}
        />
      </button>
    </form>
  );
}
