import React from 'react';

const StepLine = ({ numbe, className }) => {
  return (
    <div
      className={`h-52 w-0.5  sm:h-0.5 sm:w-20 xl:w-36  bg-primary_shade_light3 rounded-full ${className}`}
    />
  );
};

export default StepLine;
