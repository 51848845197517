import React, { useEffect, useState } from 'react';

function LazyLoadImageFromUrl({ imageUrl, fallbackSrc, alt, className }) {
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [imageUrl]);
  return (
    <figure>
      <img
        src={imageError ? fallbackSrc : imageUrl}
        onError={() => setImageError(true)}
        loading='lazy'
        alt={alt}
        className={className}
      />
    </figure>
  );
}

export default LazyLoadImageFromUrl;
